<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-process
            type="search"
            label="공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-datepicker
            label="점검년월"
            name="month"
            type="month"
            v-model="searchParam.month">
          </c-datepicker>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            type="search"
            stepperGrpCd="MIM_CHECK_STATUS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="checkStatusCd"
            label="진행상태"
            v-model="searchParam.checkStatusCd"
          ></c-select>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :editable="editable"
            label="점검자"
            name="checkUserId"
            v-model="searchParam.checkUserId">
          </c-field>
        </div> -->
        <!-- 점검일 -->
        <!-- <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            name="period"
            label="점검완료일"
            v-model="period"
          ></c-datepicker>
        </div> -->
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        일상점검결과
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <div :class="['text-grid-etc', 'grid-status-div', getColorStatus(props.row.checkStatusCd)]">
                  {{ props.row.checkStatusName }}
                </div>
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.checkDeptName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.dailyName }}</div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">{{$language('데이터가 존재하지 않습니다.')}}</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <!-- <c-table
      ref="table"
      title="일상점검 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :isTop="false"
      rowKey="minEquipmentCheckId"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="등록" :editable="editable" icon="add" @btnClicked="onItemClick('B')" />
          </q-btn-group>
        </div>
      </template>
    </c-table> -->
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mobileConfig from '@/js/mobileConfig';
export default {
  name: "equipment-daily_inspection",
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          // {
          //   name: "plantName",
          //   field: "plantName",
          //   style: 'width:100px',
          //   label: "사업장",
          //   align: "center",
          //   sortable: false,
          // },
          {
            name: "lineCd",
            field: "lineCd",
            style: 'width:80px',
            label: "라인",
            align: "center",
            sortable: false,
          },
          {
            name: "dailyName",
            field: "dailyName",
            style: 'width:200px',
            label: "점검명",
            align: "left",
            type: "link",
            sortable: false,
          },
          {
            name: "checkStatusName",
            field: "checkStatusName",
            label: "진행단계",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          // {
          //   name: 'approvalStatusName',
          //   field: 'approvalStatusName',
          //   label: '결재진행단계',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width:100px',
          // },
          // {
          //   name: "checkDate",
          //   field: "checkDate",
          //   style: 'width:100px',
          //   label: "점검월",
          //   align: "center",
          //   sortable: false,
          // },
          // {
          //   name: "checkUserName",
          //   field: "checkUserName",
          //   style: 'width:100px',
          //   label: "점검자",
          //   align: "center",
          //   sortable: false,
          // },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        month: '',
        processCd: '',
        utilityFlag: 'N',
        checkUserId: '',
      },
      period: [ '', ''],
      editable: true,
      listUrl: '',
      deleteUrl: '',
      findResultUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      selected: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.min.equipment.plan.daily.list.url;
      this.findResultUrl = mobileConfig.sop.min.equipment.result.daily.resultFind.url;
      this.searchParam.month = this.$comm.getThisMonth();

      // this.searchParam.checkUserId = this.$store.getters.user.userId
      if (this.$route.query.equipmentCd) {
        this.getDailyDetail();
      }
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'MCSC000005': // 계획수립
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'MCSC000010': // 점검중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'MCSC000015': // 점검완료
          cls = 'txt-box-grid text-positive-box';
          break;
        case 'MCSC000020': // 지연
          cls = 'txt-box-grid text-red-box';
          break;
      }
      return cls;
    },
    getColorStatus2(cd) {
      var cls = '';
      switch(cd) {
        case 'ASC0000001': // 계획수립
          cls = 'text-primary';
          break;
        case 'ASC0000002': // 점검중
          cls = 'text-positive';
          break;
        case 'ASC9999999': // 점검완료
          cls = 'text-orange-10';
          break;
      }
      return cls;
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getDailyDetail() {
      if (this.$route.query.equipmentCd) {
        this.$http.url = this.findResultUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          equipmentCd: this.$route.query.equipmentCd
        };
        this.$http.request((_result) => {
          if (_result.data) {
            this.popupOptions.param = {
              dailyCheckId: _result.data.dailyCheckId,
              equipmentCd: this.$route.query.equipmentCd
            };
            this.popupOptions.title =  this.$language('일상점검 결과') + '[' + _result.data.equipmentName + ']'; 
            this.popupOptions.target = () =>
              import(`${"./equipmentQrDailyDetail.vue"}`);
            this.popupOptions.visible = true;
            this.popupOptions.isFull = true;
            this.popupOptions.closeCallback = this.closePopup;
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '관련된 설비의 일상점검 데이터가 존재하지 않습니다.',
              type: 'warning', // success / info / warning / error
            });
          }
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '존재하지 않는 설비입니다.',
          type: 'warning', // success / info / warning / error
        });
      }
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(evt, row) {
      this.popupOptions.param = {
        dailyCheckId: row ? row.dailyCheckId : '',
      };
      this.popupOptions.title = row.dailyName; 
      this.popupOptions.target = () =>
        import(`${"./equipmentDailyDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    onItemClick(type) {
      this.linkClick(null, type);
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === 'COMPLETE' || type === 'SAVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  },
};
</script>
<style>
.blinking {
  -webkit-animation:blink 3s ease-in-out infinite alternate;
  -moz-animation:blink 3s ease-in-out infinite alternate;
  animation:blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>